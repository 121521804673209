import React from "react";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

function AuthorList({ items, title }) {
  return (
    <div className="mt-4 mb-12 border-t">
      <h2 className="font-semibold mt-2">{title}</h2>
      <ul className="list-none m-0 p-0">
        {items.map(({ author, _key }) => {
          const authorName = author && author.name;
          return (
            <li key={_key} className="text-sm my-4 flex items-center">
              <div className="flex-none mr-2">
                <div className={"w-12 h-12 relative rounded-full overflow-hidden"}>
                  {author && author.image && author.image.asset && (
                    <img
                      className="w-full h-full align-top object-cover"
                      src={imageUrlFor(buildImageObj(author.image))
                        .width(100)
                        .height(100)
                        .fit("crop")
                        .url()}
                      alt=""
                    />
                  )}
                </div>
              </div>
              <div className="flex-auto">
                <div>{authorName || <em>Missing name</em>}</div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default AuthorList;
